import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'


// Define the initial state
const initialState = {
    members: null,
    member: null,
    loading: false,
    error: null,
};

// Define the async thunk for user login
// export const getMembers = createAsyncThunk(
//     'members/getMembers',
//     async (_, { rejectWithValue }) => {
//         try {
//             const response = await api.get('/member/members');
//             // set the token in localStorage
//             return response.data;
//         } catch (error) {
//             return rejectWithValue(error.message || 'An error occurred');
//         }
//     }   
// );

export const searchMembers = createAsyncThunk(
    'members/searchMembers',
    async (egn, { rejectWithValue }) => {
        try {
            const response = await api.get(`/member/search?egn=${egn.egn}`);
            // set the token in localStorage
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

// Define the slice
const membersSlice = createSlice({
    name: 'members',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(searchMembers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchMembers.fulfilled, (state, action) => {
                state.member = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(searchMembers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
        },
});

// Export the reducer
export default membersSlice.reducer;