import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'


// Define the initial state
const initialState = {
    user: null,
    isAuthenticated: false,
    loading: false,
    error: null,
};

// Define the async thunk for user login
export const loginUser = createAsyncThunk(
    'users/login',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/login', { email, password });
            // set the token in localStorage
            localStorage.setItem('token', response.data.token);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

//refresh token
export const refreshToken = createAsyncThunk(
    'users/refreshToken',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.post('/user/refresh-token', {}, { withCredentials: true });
            // set the token in localStorage
            localStorage.setItem('token', response.data.token);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

// Define the slice
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        logoutUser(state) {
            state.user = null;
            state.isAuthenticated = false;
            localStorage.removeItem('token');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.user;
                state.isAuthenticated = true;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.isAuthenticated = false;
            })
        },
});

// Export actions
export const { logoutUser } = usersSlice.actions;
export default usersSlice.reducer;
