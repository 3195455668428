import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'
import { act } from 'react';


// Define the initial state
const initialState = {
    loading: false,
    records: [],
    error: null,
};

export const addRecord = createAsyncThunk(
    'records/addRecord',
    async ({ records }, { rejectWithValue }) => {
        try {
            const response = await api.post('/record/record', { records });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);


// Define the slice
const recordsSlice = createSlice({
    name: 'records',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addRecord.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addRecord.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.page = action.payload.page;
                state.records = action.payload.records;
            })
            .addCase(addRecord.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
        },
});

// Export actions
export default recordsSlice.reducer;
