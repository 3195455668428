import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useRouter() {
  const navigate = useNavigate();
  const location = useLocation();

  const router = useMemo(() => {
    const query = new URLSearchParams(location.search);
    const queryParams = Object.fromEntries(query.entries());

    return {
      back: () => navigate(-1),
      forward: () => navigate(1),
      reload: () => window.location.reload(),
      push: (href, data) => navigate(href, data),
      replace: (href) => navigate(href, { replace: true }),
      query: queryParams,
    };
  }, [navigate, location]);

  return router;
}
